<template>
  <div class="a1">
    <img src="../assets/主页.png" alt="" />

    <div class="a2">
      <!-- 标题栏 -->
      <div class="tag" style="margin-top: 20px">
        <div class="tag_1">
          <div
            @click="goto()"
            style="display: inline-block; margin-right: 24px"
          >
            <span class="tag_2">首页</span>
          </div>
          <div style="display: inline-block; margin-right: 24px">></div>
          <div style="display: inline-block">解决方案</div>
        </div>
        <el-divider></el-divider>
      </div>

      <!-- 解决方案内容 -->
      <div class="print_2">
        <div class="print_2_1">
         
            <el-image
              style="width: 100%"
              :src="require('@/assets/print/print0.png')"
            ></el-image>
          
          <el-divider></el-divider>
          <div class="p">
            <div>
              <el-image
                style="width: 420px; height: 500px"
                :src="require('@/assets/print/print1.png')"
              ></el-image>
            </div>

            <div class="p_0">
              <p class="p_1"><b>思迈医疗信息系统项目管理软件</b></p>
              <p class="p_2">
                &emsp;&emsp;思迈医疗信息系统项目管理软件，是一款针对医疗信息化系统项目全流程精细化管理的软件，它可以帮助医院全面了解信息化项目建设的整个过程，在过程中可实现进度跟踪、文档管理、在线编辑、审批等工作。软件内置众多的模板，可有效的提高医院信息部门在项目管理方面的工作效率。
              </p>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="p">
            <div class="p_0">
              <p class="p_1"><b>思迈医疗项目采购管理软件</b></p>
              <p class="p_2">
                &emsp;&emsp;思迈医疗项目采购管理软件，可以帮助医院规范项目采购流程，使整个流程合理、合法、合规，可引入审计及监理方的要求，在采购过程中做到各个节点有据可查有据可依。
              </p>
            </div>
            <div>
              <el-image
                style="width: 480px; height:380px"
                :src="require('@/assets/print/print2.png')"
              ></el-image>
            </div>            
          </div>
          <el-divider></el-divider>
          <div class="p">
            <div>
              <el-image
                style="width: 500px; height: 480px"
                :src="require('@/assets/print/print3.png')"
              ></el-image>
            </div>

            <div class="p_0">
              <p class="p_1"><b>思迈医疗项目审计管理软件</b></p>
              <p class="p_2">
                &emsp;&emsp;思迈医疗项目审计管理软件，可对项目的采购过程实时审计，提出整改建议，审计材料归档等。也可实时监管项目的实施过程和维护过程。
              </p>
            </div>
          </div>
                    <el-divider></el-divider>
          <div class="p">
            <div>
              <el-image
                style="width: 520px; height:440px"
                :src="require('@/assets/print/print4.png')"
              ></el-image>
            </div> 
            <div class="p_0">
              <p class="p_1"><b>思迈医疗信息系统保障管理软件</b></p>
              <p class="p_2">
                &emsp;&emsp;思迈医疗信息系统保障管理软件，可帮助医院建立信息规划、信息系统建设与完善的管理体系。也可实时监控信息系统及重要设备的运行状态，并有自动记录与报警功能。使医院真正具备信息资源的规划、建设、管理能力。
              </p>
            </div>
                       
          </div>
          <el-divider></el-divider>
          <div class="p">
            <div class="p_0">
              <p class="p_1"><b>思迈医疗运维管理软件</b></p>
              <p class="p_2">
                &emsp;&emsp;思迈医疗运维管理软件，是一款针对信息系统和信息设备的运维管理软件。能够对事件分配、值班排班、系统发布等进行智能化提醒；能实时监测运行状态；能设置定期管理检查的内容，并能够根据周期、频次等信息自动生成事件提醒、巡检提醒、变更提醒等信息。
              </p>
            </div>
            <div>
              <el-image
                style="width: 480px; height: 435px"
                :src="require('@/assets/print/print5.png')"
              ></el-image>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goto() {
      this.$router.replace("/Home1");
    },
  },
};
</script>

<style scoped>
/* 去下划线 */

.router-link {
  text-decoration: none;
}

.a1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}
.a1 .a2 {
  margin: auto;
  width: 1200px;
}
/* 标签栏 */
.a1 .a2 .tag_1 {
  width: 200px;
}
.a1 .a2 .tag_1:hover {
  cursor: pointer;
}
.a1 .a2 .tag_2:hover {
  color: skyblue;
}

/* ...内容样式 */

.a2 .print_2 {
  display: flex;
  justify-content: center;
}

.a2 .print_2 .p {
    margin: auto;
  width: 1100px;
  display: flex;
  justify-content: space-between;
}

/* 文本框 */
.a2 .print_2 .p .p_0{
     margin-top: 80px;
 
  width: 550px;
}
.a2 .print_2 .p .p_1 {
 line-height: 48px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  color: #1f497d;
  text-align: center;
}

.a2 .print_2 .p .p_2{
    margin-top: 36px;
    width: 550px;
    font-size: 18px;
    line-height: 32px;
  font-family: Microsoft YaHei;
  
  
}

/* 标题下边的线 */
.a2 .print_2 .print_2_1 .p .el-divider--horizontal {
  margin-top: 0;
  margin-bottom: 24px;
}
</style>
